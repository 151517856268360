import {
  GET_ALL_SOCIALTYPES,
  GET_ALL_SOCIALTYPES_SUCCESS,
  GET_ALL_SOCIALTYPES_FAILURE,

  EDIT_SOCIALTYPE,
  EDIT_SOCIALTYPE_SUCCESS,
  EDIT_SOCIALTYPE_FAILURE,

  DELETE_SOCIALTYPE,
  DELETE_SOCIALTYPE_SUCCESS,
  DELETE_SOCIALTYPE_FAILURE,
  GET_SOCIALTYPE,
  GET_SOCIALTYPE_SUCCESS,
  GET_SOCIALTYPE_FAILURE,
  ADD_SOCIALTYPE,
  ADD_SOCIALTYPE_SUCCESS,
  ADD_SOCIALTYPE_FAILURE,

} from "./actionTypes";


const initialState = {
  socialTypes: [],
  loading: false,
  error: "",
  socialType: {}
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all socialTypes  
    case GET_ALL_SOCIALTYPES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_ALL_SOCIALTYPES_SUCCESS:

      state = {
        ...state,
        loading: false,
        socialTypes: action.payload.socialTypes

      };
      break;

    case GET_ALL_SOCIALTYPES_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;

    // edit social type 
    case EDIT_SOCIALTYPE:
      state = { ...state, loading: true }

      break;

    case EDIT_SOCIALTYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        socialType: action.payload.social
      }
      break;

    case EDIT_SOCIALTYPE_FAILURE:
      state = {
        ...state, loading: false, error: action.payload
      }
      break;

    // get social type 
    case GET_SOCIALTYPE:
      state = { ...state, loading: true }

      break;

    case GET_SOCIALTYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        socialType: action.payload.socialType
      }
      break;

    case GET_SOCIALTYPE_FAILURE:
      state = {
        ...state, loading: false, error: action.payload
      }
      break;

    // delete socialType 
    case DELETE_SOCIALTYPE:
      state = {
        ...state,
        loading: true
      }

      break;

    case DELETE_SOCIALTYPE_SUCCESS:

      const socialTypesAfterDeleteing = [
        ...state?.socialTypes?.filter((socialType) => socialType?.id !== action.payload),
      ];

      state = {
        ...state,
        loading: false,
        socialTypes: socialTypesAfterDeleteing
      }
      break;

    case DELETE_SOCIALTYPE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload
      }
      break;

    // edit social type 
    case ADD_SOCIALTYPE:
      state = { ...state, loading: true }

      break;

    case ADD_SOCIALTYPE_SUCCESS:
      state = {
        ...state,
        loading: false,
        socialType: action.payload.socialType
      }
      break;

    case ADD_SOCIALTYPE_FAILURE:
      state = {
        ...state, loading: false, error: action.payload
      }
      break;


    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
