import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { DELETE_SUBSCRIBER, GET_ALL_SUBSCRIBERS } from "./actionTypes";
import { getAllSubscribersApi, deleteSubscriberApi } from "api/subscribers";

import {
  deleteSubscriberFailure,
  deleteSubscriberSuccess,
  getAllSubscribersFailure,
  getAllSubscribersSuccess
} from "./actions";

function* getAllSubscribers({ payload }) {
  try {
    const { data } = yield call(getAllSubscribersApi, payload);
    yield put(getAllSubscribersSuccess(data));
  } catch (error) {
    yield put(getAllSubscribersFailure(error.response.data || error));
  }
}

function* deleteSubscriber({ payload }) {

  try {
    yield call(deleteSubscriberApi, payload);

    yield put(deleteSubscriberSuccess(payload));
  } catch (error) {
    yield put(deleteSubscriberFailure(error.response.data || error));
  }
}

export function* watchDeleteSubscriber() {
  yield takeEvery(DELETE_SUBSCRIBER, deleteSubscriber);
}

export function* watchGetAllPageSubscriber() {
  yield takeEvery(GET_ALL_SUBSCRIBERS, getAllSubscribers);
}

function* subscribersSaga() {
  yield all([
    fork(watchGetAllPageSubscriber),
    fork(watchDeleteSubscriber),
  ]);
}

export default subscribersSaga;
