import React from "react";
import removeImg from "assets/svgs/close.svg";
import { v4 } from "uuid";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const SlidersArray = ({ sliders, setSliders }) => {
  const handleInputOnChange = (e, id) => {
    const { name, value } = e.target;

    const newSlidersArray = sliders?.map((slider) => {
      if (slider.id !== id) return slider;

      // make an object with locals inside the big one
      const inputName = name?.split(".")?.[0];
      const local = name?.split(".")?.[1];

      if (local) {
        return {
          ...slider,
          [inputName]: {
            ...slider?.[inputName],
            [local]: value,
          },
        };
      }

      return { ...slider, [name]: value };
    });

    setSliders(newSlidersArray);
  };

  const removeSlider = (sliderId) => {
    const newSliders = sliders?.filter(
      (slider, index) => slider.id !== sliderId
    );
    setSliders(() => newSliders);
  };

  const addSlider = () => {
    setSliders(() => [...(sliders || []), { id: v4(), name: {}, comment: {} }]);
  };

  return (
    <div className="form-group branches sliders">
      <Row>
        <h5>
          <FormattedMessage id={"clients"} />
        </h5>

        {sliders?.map((slider, index) => {
          return (
            <Col xl={6} xs={12} key={slider.id} className="">
              <div className="add-multi-component">
                <Row>
                  <button
                    type="button"
                    onClick={() => removeSlider(slider.id)}
                    className="removeImg"
                  >
                    <img src={removeImg} alt="" />
                  </button>
                  {/* name */}
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        {" "}
                        <FormattedMessage id={"name"} /> ({" "}
                        <FormattedMessage id={"arabic-language"} /> )
                      </h5>
                      <input
                        type="text"
                        name="name.ar"
                        className="form-control form-outline"
                        onChange={(e) => handleInputOnChange(e, slider.id)}
                        value={slider?.name.ar}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        {" "}
                        <FormattedMessage id={"name"} /> ({" "}
                        <FormattedMessage id={"english-language"} /> )
                      </h5>
                      <input
                        type="text"
                        name="name.en"
                        className="form-control form-outline"
                        onChange={(e) => handleInputOnChange(e, slider.id)}
                        value={slider?.name?.en}
                      />
                    </div>
                  </Col>
                  {/* opinion */}
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id={"opinion"} /> ({" "}
                        <FormattedMessage id={"arabic-language"} /> )
                      </h5>
                      <textarea
                        type="text"
                        name="opinion.ar"
                        className="form-control form-outline"
                        onChange={(e) => handleInputOnChange(e, slider.id)}
                        value={slider?.opinion?.ar}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id={"opinion"} /> ({" "}
                        <FormattedMessage id={"english-language"} /> )
                      </h5>
                      <textarea
                        type="text"
                        name="opinion.en"
                        dir="ltr"
                        className="form-control form-outline"
                        onChange={(e) => handleInputOnChange(e, slider.id)}
                        value={slider?.opinion?.en}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          );
        })}

        <Row>
          <Col lg={4} xs={12} className="text-left">
            <button
              type="button"
              className="btn btn-green add_new_section__"
              onClick={() => {
                addSlider();
              }}
            >
              <FormattedMessage id={"addClient"} />
            </button>
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default SlidersArray;
