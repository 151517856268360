import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import { editSection, getSection } from "store/actions";
import Loader from "components/shared/Loader";
import ImageUpload from "components/shared/ImageUpload";
import { Input } from "components/shared/FormComponents";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

const EditPartner = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, error, section } = useSelector((state) => state.section);

  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // effects
  useEffect(() => {
    dispatch(getSection({ page: "partners", slug: id }));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      heading: section?.heading,
    });

    setSelectedImage({
      preview: null,
      path: section?.image,
    });
  }, [reset, section]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    if (!selectedImage?.path) {
      toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
      return;
    }

    data.image = `/uploads/${selectedImage?.path?.split("/").pop()}`;

    dispatch(
      editSection({
        slug: id,
        page: "partners",
        data: { section: data },
        navigate,
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"editPartner"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* image  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"image"} />
                      </h5>

                      <ImageUpload
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  </Col>
                </Row>

                {/* heading  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="heading.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="heading.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"saveChanges"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPartner;
