import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";

import GuestRoute from "helpers/authentication/guestRoute";
import AuthRoute from "helpers/authentication/authRoute";

import Layout from "./components/Layout/Layout";
import Dashboard from "views/dashboard";
import NotFound from "views/NotFound";
import Login from "views/login";
import Logout from "views/Logout";
import { getAllSettings, getCurrentUser } from "store/actions";
import Loader from "./components/shared/Loader";

import Settings from "views/Settings";

import Intro from "views/home/intro";
import About from "views/home/about";
import Offers from "views/home/offers";

// import Brief from "views/about/brief";
// import Features from "views/about/features";
// import WhyUs from "views/about/whyUs";

import Projects from "views/projects";
import EditProject from "views/projects/EditProject";
import AddProject from "views/projects/AddProject";
import ProjectsHeading from "views/projects/Heading";

import Systems from "views/systems";
import EditSystem from "views/systems/EditSystem";
import AddSystem from "views/systems/AddSystem";
import SystemsHeading from "views/systems/Heading";

import Partners from "views/partners";
import EditPartner from "views/partners/EditPartner";
import AddPartner from "views/partners/AddPartner";
import PartnersHeading from "views/partners/Heading";

import Subscribers from "views/subscribers";
import ContactUs from "views/ContactUs";

import Socials from "views/socials";
import AddSocial from "views/socials/AddSocial";
import EditSocial from "views/socials/EditSocial";
import ShowContact from "views/ContactUs/ShowContact";
import EditCategory from "views/categories/EditCategory";
import AddCategory from "views/categories/AddCategory";
import Categories from "views/categories";
import CategoriesHeading from "views/categories/Heading";
import Vendors from "views/vendors";
import AddVendor from "views/vendors/AddVendor";
import EditVendor from "views/vendors/EditVendor";
import Video from "views/home/video";
import AddProduct from "views/products/AddProduct";
import EditProduct from "views/products/EditProduct";
import Products from "views/products";
import Clients from "views/home/clients";
import AboutUs from "views/about";

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  //selectors
  const { isLoggedIn } = useSelector((state) => state?.authentication);
  const { settings } = useSelector((state) => state.settings);
  const logo = settings?.appLogo;

  useEffect(() => {
    dispatch(getAllSettings());
  }, [dispatch]);

  useEffect(() => {
    if (token) dispatch(getCurrentUser());
  }, [token, dispatch]);

  const RenderApp = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout logo={logo} />}>
              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />

              {/* home  */}
              <Route
                path="/home/intro"
                element={
                  <AuthRoute>
                    <Intro />
                  </AuthRoute>
                }
              />

              <Route
                path="/home/about"
                element={
                  <AuthRoute>
                    <About />
                  </AuthRoute>
                }
              />

              <Route
                path="/home/offers"
                element={
                  <AuthRoute>
                    <Offers />
                  </AuthRoute>
                }
              />
              <Route
                path="/home/video"
                element={
                  <AuthRoute>
                    <Video />
                  </AuthRoute>
                }
              />
              <Route
                path="/clients"
                element={
                  <AuthRoute>
                    <Clients />
                  </AuthRoute>
                }
              />

              {/* products */}
              <Route
                path="/products"
                element={
                  <AuthRoute>
                    <Products />
                  </AuthRoute>
                }
              />
              <Route
                path="/products/addProduct"
                element={
                  <AuthRoute>
                    <AddProduct />
                  </AuthRoute>
                }
              />
              <Route
                path="/products/editProduct/:id"
                element={
                  <AuthRoute>
                    <EditProduct />
                  </AuthRoute>
                }
              />
              {/* vendors */}
              <Route
                path="/vendors"
                element={
                  <AuthRoute>
                    <Vendors />
                  </AuthRoute>
                }
              />
              <Route
                path="/vendors/addVendor"
                element={
                  <AuthRoute>
                    <AddVendor />
                  </AuthRoute>
                }
              />
              <Route
                path="/vendors/editVendor/:id"
                element={
                  <AuthRoute>
                    <EditVendor />
                  </AuthRoute>
                }
              />

              {/* about  */}
              <Route
                path="/aboutUs"
                element={
                  <AuthRoute>
                    <AboutUs />
                  </AuthRoute>
                }
              />
              {/* <Route
                path="/about/brief"
                element={
                  <AuthRoute>
                    <Brief />
                  </AuthRoute>
                }
              />

              <Route
                path="/about/features"
                element={
                  <AuthRoute>
                    <Features />
                  </AuthRoute>
                }
              />
              <Route
                path="/about/why-us"
                element={
                  <AuthRoute>
                    <WhyUs />
                  </AuthRoute>
                }
              /> */}

              {/* projects */}
              <Route
                path="/categories"
                element={
                  <AuthRoute>
                    <Categories />
                  </AuthRoute>
                }
              />

              <Route
                path="/categories/add"
                element={
                  <AuthRoute>
                    <AddCategory />
                  </AuthRoute>
                }
              />
              <Route
                path="/categories/heading"
                element={
                  <AuthRoute>
                    <CategoriesHeading />
                  </AuthRoute>
                }
              />

              <Route
                path="/categories/:id"
                element={
                  <AuthRoute>
                    <EditCategory />
                  </AuthRoute>
                }
              />

              {/* projects */}
              <Route
                path="/projects"
                element={
                  <AuthRoute>
                    <Projects />
                  </AuthRoute>
                }
              />

              <Route
                path="/projects/addProject"
                element={
                  <AuthRoute>
                    <AddProject />
                  </AuthRoute>
                }
              />
              <Route
                path="/projects/heading"
                element={
                  <AuthRoute>
                    <ProjectsHeading />
                  </AuthRoute>
                }
              />

              <Route
                path="/projects/:id"
                element={
                  <AuthRoute>
                    <EditProject />
                  </AuthRoute>
                }
              />

              {/* systems */}
              <Route
                path="/systems"
                element={
                  <AuthRoute>
                    <Systems />
                  </AuthRoute>
                }
              />

              <Route
                path="/systems/AddSystem"
                element={
                  <AuthRoute>
                    <AddSystem />
                  </AuthRoute>
                }
              />
              <Route
                path="/systems/heading"
                element={
                  <AuthRoute>
                    <SystemsHeading />
                  </AuthRoute>
                }
              />

              <Route
                path="/systems/:id"
                element={
                  <AuthRoute>
                    <EditSystem />
                  </AuthRoute>
                }
              />

              {/* partners */}
              <Route
                path="/partners"
                element={
                  <AuthRoute>
                    <Partners />
                  </AuthRoute>
                }
              />

              <Route
                path="/partners/addPartner"
                element={
                  <AuthRoute>
                    <AddPartner />
                  </AuthRoute>
                }
              />

              <Route
                path="/partners/heading"
                element={
                  <AuthRoute>
                    <PartnersHeading />
                  </AuthRoute>
                }
              />

              <Route
                path="/partners/:id"
                element={
                  <AuthRoute>
                    <EditPartner />
                  </AuthRoute>
                }
              />

              {/* subscribers */}
              <Route
                path="/subscribers"
                element={
                  <AuthRoute>
                    <Subscribers />
                  </AuthRoute>
                }
              />

              <Route
                path="/contactUs"
                element={
                  <AuthRoute>
                    <ContactUs />
                  </AuthRoute>
                }
              />

              <Route
                path="/contactUs/show/:id"
                element={
                  <AuthRoute>
                    <ShowContact />
                  </AuthRoute>
                }
              />

              {/* socials */}
              <Route
                path="/socials"
                element={
                  <AuthRoute>
                    <Socials />
                  </AuthRoute>
                }
              />

              <Route
                path="/socials/addSocial"
                element={
                  <AuthRoute>
                    <AddSocial />
                  </AuthRoute>
                }
              />

              <Route
                path="/socials/:id"
                element={
                  <AuthRoute>
                    <EditSocial />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings"
                element={
                  <AuthRoute>
                    <Settings />
                  </AuthRoute>
                }
              />

              <Route
                path="*"
                element={
                  <AuthRoute>
                    <NotFound />
                  </AuthRoute>
                }
              />
            </Route>

            {/*Auth Routes */}
            <Route
              path="/logout"
              element={
                <AuthRoute>
                  <Logout />
                </AuthRoute>
              }
            />
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <Login logo={logo} />
                </GuestRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  };

  if (!token) return RenderApp();
  if (isLoggedIn !== null) return RenderApp();
  return <Loader />;
};

export default App;
