import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { addSection } from "store/actions";
import ImageUpload from "components/shared/ImageUpload";
import { EditorComponent, Input } from "components/shared/FormComponents";
import AddButton from "components/shared/AddButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

const AddressType = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.section);
  const [description, setDescription] = useState({ ar: "", en: "" });
  const [additionalDescription, setAdditionalDescription] = useState({
    ar: "",
    en: "",
  });
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });
  const [selectedIcon, setSelectedIcon] = useState({ preview: null, path: "" });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    if (!selectedImage?.path) {
      toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
      return;
    }

    if (!selectedIcon?.path) {
      toastErrorMessage(formatMessage({ id: "iconErrorMessage" }), locale);
      return;
    }

    data.description = description;
    data.additionalDescription = additionalDescription;

    data.image = `/uploads/${selectedImage?.path?.split("/").pop()}`;
    data.icon = `/uploads/${selectedIcon?.path?.split("/").pop()}`;
    // data.title = data?.heading?.en

    dispatch(
      addSection({ page: "systems", data: { section: data }, navigate })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"addSystem"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* image  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"image"} />
                      </h5>
                      <ImageUpload
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  </Col>
                </Row>
                {/* icon  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"icon"} />
                      </h5>
                      <ImageUpload
                        selectedImage={selectedIcon}
                        setSelectedImage={setSelectedIcon}
                      />
                    </div>
                  </Col>
                </Row>

                {/* heading  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="heading.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="heading.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* description  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} /> (
                        <FormattedMessage id={"arabic-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="description.ar"
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name="description.ar"
                            locale="ar"
                            setData={setDescription}
                            initialValue=""
                          />
                        )}
                      />
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} /> (
                        <FormattedMessage id={"english-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="description.en"
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name="description"
                            locale="en"
                            setData={setDescription}
                            initialValue=""
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                {/* additionalDescription  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"additionalDescription"} /> (
                        <FormattedMessage id={"arabic-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="additionalDescription.ar"
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name="additionalDescription.ar"
                            locale="ar"
                            setData={setAdditionalDescription}
                            initialValue=""
                          />
                        )}
                      />
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"additionalDescription"} /> ({" "}
                        <FormattedMessage id={"english-language"} /> )
                      </h5>

                      <Controller
                        control={control}
                        name="additionalDescription.en"
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name="additionalDescription"
                            locale="en"
                            setData={setAdditionalDescription}
                            initialValue=""
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                <AddButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressType;
