import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { addSection, getAllPageSections } from "store/actions";
import ImageUpload from "components/shared/ImageUpload";
import { Input } from "components/shared/FormComponents";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import Select from "react-select";
import ImagesUpload from "components/shared/ImagesUpload";

const AddProduct = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, sections } = useSelector((state) => state.section);
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });
  const [selectedImages, setSelectedImages] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getAllPageSections("products"));
  }, [dispatch]);
  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    if (!selectedImage?.path) {
      toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
      return;
    }
    data.image = `/uploads/${selectedImage?.path?.split("/").pop()}`;
    data.title = data?.name?.en;

    if (selectedImages?.length > 0) {
      data.images = selectedImages.map(
        (image) => `/uploads/${image?.path?.split("/").pop()}`
      );
    }

    dispatch(
      addSection({ page: "products", data: { section: data }, navigate })
    );
  };

  const handleAddOption = (name) => {
    let value = getValues("newOption");
    setValue(name, value);
    setValue("newOption");
  };

  const productsCategories = [
    ...[
      ...new Set(
        (sections || [])
          ?.filter((e) => e?.category)
          ?.map((e) => e?.category?.ar)
      ),
    ]?.map(
      (arCat) => sections?.find((e) => e?.category?.ar == arCat)?.category
    ),
    { label: formatMessage({ id: "new-option" }), value: "add-new-category" },
  ];
  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"addProduct"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* image  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"image"} />
                      </h5>
                      <ImageUpload
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  </Col>
                </Row>

                {/* name  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="name"
                      name="name.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="name"
                      name="name.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* category  */}
                {watch("category") == "add-new-category" && (
                  <div className="d-flex align-items-center gap-2 bg-secondary bg-opacity-10 rounded-4 p-3 form-group">
                    {/* <h5>
                      <FormattedMessage id="add-new-category" />
                    </h5> */}
                    <Input
                      langId="new-category"
                      name="newOption.ar"
                      register={register}
                      errors={errors}
                    />
                    <Input
                      langId="new-category"
                      name="newOption.en"
                      register={register}
                      errors={errors}
                    />
                    <button
                      type="button"
                      className="btn btn-blue w-auto"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() => handleAddOption("category")}
                    >
                      <span>
                        <FormattedMessage id="done" />
                      </span>
                    </button>
                  </div>
                )}
                <Row>
                  <Col>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id="category" /> (
                        <FormattedMessage id="arabic-language" />)
                      </h5>

                      <Controller
                        control={control}
                        name="category"
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            inputRef={ref}
                            name={name}
                            placeholder={<FormattedMessage id={"category"} />}
                            options={productsCategories?.map((c) => ({
                              value: c?.value || c,
                              label: c?.label || c?.[locale],
                            }))}
                            onChange={(selectedOption) => {
                              onChange(selectedOption.value);
                            }}
                            value={{ value: value, label: value?.[locale] }}
                            isRtl={true}
                            isSearchable={false}
                            className="basic-single"
                            classNamePrefix="select"
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
                {/* price  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      type="date"
                      langId="date"
                      name="date"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      type="number"
                      langId="price"
                      name="price"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"images"} />
                      </h5>

                      <ImagesUpload
                        imagesURLs={selectedImages}
                        setImageURLs={setSelectedImages}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"add"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
