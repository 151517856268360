import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { FormattedMessage, useIntl } from "react-intl";
import { editSection, getSection } from "store/actions";
import Loader from "components/shared/Loader";
import { useState } from "react";
import ImageUpload from "components/shared/ImageUpload";
import { EditorComponent, Input } from "components/shared/FormComponents";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import FeaturesArray from "./FeaturesArray";

const AboutUs = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const { loading, error, section } = useSelector((state) => state.section);
  const [firstSelectedImage, setFirstSelectedImage] = useState({
    preview: null,
    path: "",
  });
  const [secondSelectedImage, setSecondSelectedImage] = useState({
    preview: null,
    path: "",
  });
  const [videoSelectedImage, setVideoelectedImage] = useState({
    preview: null,
    path: "",
  });
  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [firstDescription, setFirstDescription] = useState({ ar: "", en: "" });
  const [secondDescription, setSecondDescription] = useState({
    ar: "",
    en: "",
  });

  // get single page by slug
  useEffect(() => {
    dispatch(
      getSection({
        page: "about",
        slug: "info",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    reset({
      ...section,
      // heading: section?.heading,
      // description: section?.description,
      // image: section?.image,
    });

    setFirstSelectedImage({
      preview: null,
      path: section?.firstImage,
    });
    setSecondSelectedImage({
      preview: null,
      path: section?.secondImage,
    });
    setVideoelectedImage({
      preview: null,
      path: section?.videoImage,
    });
    setFirstDescription(section?.firstDescription);
    setSecondDescription(section?.secondDescription);
  }, [reset, section]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    if (!firstSelectedImage?.path) {
      toastErrorMessage(
        `${formatMessage({ id: "imageErrorMessage" })} , ${formatMessage({
          id: "firstSection",
        })}`,
        locale
      );
      return;
    }
    if (!videoSelectedImage?.path) {
      toastErrorMessage(
        `${formatMessage({ id: "imageErrorMessage" })} , ${formatMessage({
          id: "secondSection",
        })}`,
        locale
      );
      return;
    }
    if (!secondSelectedImage?.path) {
      toastErrorMessage(
        `${formatMessage({ id: "imageErrorMessage" })} , ${formatMessage({
          id: "thirdSection",
        })}`,
        locale
      );
      return;
    }

    data.firstDescription = firstDescription;
    data.secondDescription = secondDescription;
    data.firstImage = `/uploads/${firstSelectedImage?.path?.split("/").pop()}`;
    data.secondImage = `/uploads/${secondSelectedImage?.path
      ?.split("/")
      .pop()}`;
    data.videoImage = `/uploads/${videoSelectedImage?.path?.split("/").pop()}`;

    dispatch(
      editSection({ slug: "info", page: "about", data: { section: data } })
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"firstSection"} /> (
              <FormattedMessage id={"overView"} />)
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* image  */}
                <Col lg={12} xs={12}>
                  <div className="form-group required">
                    <h5>
                      <FormattedMessage id={"image"} />
                    </h5>
                    <ImageUpload
                      selectedImage={firstSelectedImage}
                      setSelectedImage={setFirstSelectedImage}
                    />
                  </div>
                </Col>

                {/* heading  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="firstHeading.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="firstHeading.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* description  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"arabic-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="firstDescription.ar"
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name="firstDescription.ar"
                            locale="ar"
                            setData={setFirstDescription}
                            initialValue={section?.firstDescription?.ar}
                          />
                        )}
                      />
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"english-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="firstDescription.en"
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name="firstDescription.en"
                            locale="en"
                            setData={setFirstDescription}
                            initialValue={section?.firstDescription?.en}
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>

                {/* <SaveChangesButton /> */}
              </Col>
            </form>
          </div>
        </div>
        {/* ================================================= */}
        {/* ================================================= */}
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"secondSection"} /> (
              <FormattedMessage id={"video"} />)
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* image  */}
                <Col lg={12} xs={12}>
                  <div className="form-group required">
                    <h5>
                      <FormattedMessage id={"image"} />
                    </h5>
                    <ImageUpload
                      selectedImage={videoSelectedImage}
                      setSelectedImage={setVideoelectedImage}
                    />
                  </div>
                </Col>

                {/* heading  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="url"
                      type="url"
                      name="url"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* <SaveChangesButton /> */}
              </Col>
            </form>
          </div>
        </div>
        {/* ================================================= */}
        {/* ================================================= */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card">
            <div className="card-head">
              <h4>
                <FormattedMessage id={"secondSection"} /> (
                <FormattedMessage id={"story"} />)
              </h4>
            </div>
            <div className="card-body">
              <Col xl={8} md={12}>
                {/* image  */}
                <Col lg={12} xs={12}>
                  <div className="form-group required">
                    <h5>
                      <FormattedMessage id={"image"} />
                    </h5>
                    <ImageUpload
                      selectedImage={secondSelectedImage}
                      setSelectedImage={setSecondSelectedImage}
                    />
                  </div>
                </Col>

                {/* heading  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="secondHeading.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="secondHeading.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* description  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"arabic-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="secondDescription.ar"
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name="secondDescription.ar"
                            locale="ar"
                            setData={setSecondDescription}
                            initialValue={section?.secondDescription?.ar}
                          />
                        )}
                      />
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group ">
                      <h5>
                        <FormattedMessage id={"description"} />
                        (<FormattedMessage id={"english-language"} />)
                      </h5>

                      <Controller
                        control={control}
                        name="secondDescription.en"
                        render={({ field: { value } }) => (
                          <EditorComponent
                            name="secondDescription"
                            locale="en"
                            setData={setSecondDescription}
                            initialValue={section?.secondDescription?.en}
                          />
                        )}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
          <Row>
            <Col lg={12}>
              <FeaturesArray {...{ control, register, errors }} />
            </Col>
          </Row>
          <SaveChangesButton />
        </form>
      </div>
    </>
  );
};

export default AboutUs;
