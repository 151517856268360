import ControlArea from "components/Layout/ControlArea";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAllPageSections } from "store/actions";
import Icon from "../../assets/svgs/client.svg";
import Table from "./Table";

const Vendors = () => {
  const { sections, loading, error } = useSelector((state) => state.section);
  const dispatch = useDispatch();
  const { formatMessage, locale } = useIntl();

  useEffect(() => {
    dispatch(getAllPageSections("vendors"));
  }, [dispatch]);

  // error
  useEffect(() => {
    if (error) {
      toast.error(formatMessage({ id: "sendingErrorMessage" }), {
        position: locale === "ar" ? "bottom-left" : "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [error, formatMessage, locale]);

  return (
    <>
      <div className="partners-wrap">
        <ControlArea
          btnTxt={<FormattedMessage id={"addVendor"} />}
          cardTxt={<FormattedMessage id={"vendors"} />}
          icon={Icon}
          url="/vendors/addVendor"
        />
        <Table data={sections} loading={loading} />
      </div>
    </>
  );
};

export default Vendors;
