import {
  ADD_SECTION,
  ADD_SECTION_FAILURE,
  ADD_SECTION_SUCCESS,
  DELETE_SECTION,
  DELETE_SECTION_FAILURE,
  DELETE_SECTION_SUCCESS,
  EDIT_SECTION, EDIT_SECTION_FAILURE, EDIT_SECTION_SUCCESS,
  GET_ALL_PAGE_SECTIONS,
  GET_ALL_PAGE_SECTIONS_FAILURE,
  GET_ALL_PAGE_SECTIONS_SUCCESS,
  GET_SECTION, GET_SECTION_FAILURE, GET_SECTION_SUCCESS
} from "./actionTypes";


const initialState = {
  loading: false,
  error: "",
  sections: [],
  section: {}
};

const stores = (state = initialState, action) => {
  switch (action.type) {

    // get section  
    case GET_ALL_PAGE_SECTIONS:
      state = {
        ...state,
        error: "",
        loading: true,
        sections: []
      };
      break;

    case GET_ALL_PAGE_SECTIONS_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        sections: action.payload.sections,
      };
      break;

    case GET_ALL_PAGE_SECTIONS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        sections: [],
        loading: false
      };
      break;

    // get section  
    case GET_SECTION:
      state = {
        ...state,
        error: "",
        loading: true,
        section: {}
      };
      break;

    case GET_SECTION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        section: action.payload.section,
      };
      break;

    case GET_SECTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ADD section  
    case ADD_SECTION:
      state = {
        ...state,
        error: "",
        loading: true,
        section: {}
      };
      break;

    case ADD_SECTION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        section: {},
        sections: action.payload.sections,
      };
      break;

    case ADD_SECTION_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;

    // edit section  
    case EDIT_SECTION:
      state = {
        ...state,
        error: "",
        loading: true,
        section: {}
      };
      break;

    case EDIT_SECTION_SUCCESS:
      state = {
        ...state,
        error: "",
        loading: false,
        section: action.payload,
      };
      break;

    case EDIT_SECTION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // ADD section  
    case DELETE_SECTION:
      state = {
        ...state,
        error: "",
        loading: true,
      };
      break;

    case DELETE_SECTION_SUCCESS:
      const sectionsAfterDelete = [
        ...state?.sections?.filter((section) => section?.slug !== action.payload.slug),
      ];
      state = {
        ...state,
        error: "",
        loading: false,
        sections: sectionsAfterDelete,
      };
      break;

    case DELETE_SECTION_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;

    default:
      state = { ...state };
      break;
  }

  return state;
};

export default stores;
