import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { addSection } from "store/actions";
import ImageUpload from "components/shared/ImageUpload";
import { Input } from "components/shared/FormComponents";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";

const AddVendor = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error } = useSelector((state) => state.section);
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });
  const [position, setPosition] = useState([25, 45]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    if (!selectedImage?.path) {
      toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
      return;
    }
    data.image = `/uploads/${selectedImage?.path?.split("/").pop()}`;
    data.title = data?.name?.en;
    data.position = position;

    dispatch(
      addSection({ page: "vendors", data: { section: data }, navigate })
    );
  };

  const handleMark = (event) => {
    setPosition(Object.values(event.latlng));
  };
  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"addVendor"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                {/* image  */}
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"image"} />
                      </h5>
                      <ImageUpload
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  </Col>
                </Row>

                {/* name  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="name"
                      name="name.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="name"
                      name="name.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* area  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="area"
                      name="area.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="area"
                      name="area.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
                {/* city */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="city"
                      name="city.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="city"
                      name="city.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* rate  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="rate"
                      name="rate"
                      register={register}
                      errors={errors}
                    />
                  </Col>

                  {/* phone  */}
                  <Col lg={6} xs={12}>
                    <Input
                      langId="phone"
                      name="phone"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>
                {/* map */}
                <Row>
                  <Col>
                    <MapContainer
                      center={position}
                      zoom={13}
                      // scrollWheelZoom={false}
                      style={{ height: "500px" }}
                      // onclick={handleMapClick}
                    >
                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                      <Marker
                        position={position}
                        draggable={true}
                        onDragEnd={handleMark}
                        icon={
                          new Icon({
                            iconUrl: markerIconPng,
                            iconSize: [25, 41],
                            iconAnchor: [12, 41],
                          })
                        }
                      />

                      <MapEvents onCreate={handleMark} />
                    </MapContainer>
                  </Col>
                </Row>
                <br />

                <Row>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id={"add"} />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
function MapEvents({ onCreate }) {
  // const [map, setMap] = useState(null);
  const map = useMapEvents({
    click: (event) => {
      // console.log("event", event);
      onCreate(event);
      // map.locate();
    },
    // locationfound: (location) => {
    //   console.log("location found:", location);
    // },
  });

  return <></>;
}

export default AddVendor;
