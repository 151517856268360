export const GET_ALL_SOCIALS = "GET_ALL_SOCIALS";
export const GET_ALL_SOCIALS_SUCCESS = "GET_ALL_SOCIALS_SUCCESS";
export const GET_ALL_SOCIALS_FAILURE = "GET_ALL_SOCIALS_FAILURE";

export const EDIT_SOCIAL = "EDIT_SOCIAL";
export const EDIT_SOCIAL_SUCCESS = "EDIT_SOCIAL_SUCCESS";
export const EDIT_SOCIAL_FAILURE = "EDIT_SOCIAL_FAILURE";

export const GET_SOCIAL = "GET_SOCIAL";
export const GET_SOCIAL_SUCCESS = "GET_SOCIAL_SUCCESS";
export const GET_SOCIAL_FAILURE = "GET_SOCIAL_FAILURE";

export const DELETE_SOCIAL = "DELETE_SOCIAL";
export const DELETE_SOCIAL_SUCCESS = "DELETE_SOCIAL_SUCCESS";
export const DELETE_SOCIAL_FAILURE = "DELETE_SOCIAL_FAILURE";

export const ADD_SOCIAL = "ADD_SOCIAL";
export const ADD_SOCIAL_SUCCESS = "ADD_SOCIAL_SUCCESS";
export const ADD_SOCIAL_FAILURE = "ADD_SOCIAL_FAILURE";