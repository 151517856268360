const { default: server } = require("./server");

export const getAllSocialsApi = async () => {
  const response = await server().get("/settings/socials");

  return response.data;
};

export const editSocialApi = async ({ data, id }) => {

  const response = await server().put(`/settings/socials/${id}`, data);

  return response.data;
};

export const getSocialApi = async (id) => {
  const response = await server().get(`/settings/socials/${id}`);

  return response.data;
};

export const deleteSocialApi = async (id) => {
  const response = await server().delete(`/settings/socials/${id}`);

  return response.data;
}

export const addSocialApi = async (data) => {
  const response = await server().post("/settings/socials", data);

  return response.data;
}
