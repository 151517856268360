import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import { getCurrentUser, postUserLogin } from "../../api/users";

// Login Redux States
import { CURRENT_USER, LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import {
  apiError,
  getCurrentUserFailure,
  getCurrentUserSuccess,
  loginSuccess,
  logoutUserSuccess,
} from "./actions";

function* loginUser({ payload }) {
  try {

    const { data } = yield call(postUserLogin, payload.data);
    yield put(loginSuccess(data));
    localStorage.setItem("token", data.token);

  } catch (error) {

    yield put(apiError(error.response.data.errors[0].msg));
  }
}

function* logoutUser() {
  try {
    yield put(logoutUserSuccess());
    localStorage.removeItem("token");
    localStorage.removeItem("store");
  } catch (error) {
    yield put(apiError(error.response.data.errors[0].msg));
  }
}

function* currentUser() {

  try {
    const { data } = yield call(getCurrentUser);

    yield put(getCurrentUserSuccess(data));
  } catch (error) {

    yield put(getCurrentUserFailure(error.response.data.error || error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchCurrentUser() {
  yield takeEvery(CURRENT_USER, currentUser);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchCurrentUser),
  ]);
}

export default authSaga;
