import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { ADD_SECTION, DELETE_SECTION, EDIT_SECTION, GET_ALL_PAGE_SECTIONS, GET_SECTION } from "./actionTypes";
import { getSectionApi, editSectionApi, getAllPageSectionsApi, addSectionApi, deleteSectionApi } from "api/section";

import {
  addSectionFailure,
  addSectionSuccess,
  deleteSectionFailure,
  deleteSectionSuccess,
  editSectionFailure,
  editSectionSuccess,
  getAllPageSectionsFailure,
  getAllPageSectionsSuccess,
  getSectionFailure, getSectionSuccess
} from "./actions";

function* getAllPageSections({ payload }) {
  try {
    const { data } = yield call(getAllPageSectionsApi, payload);
    yield put(getAllPageSectionsSuccess(data));
  } catch (error) {
    yield put(getAllPageSectionsFailure(error.response.data || error));
  }
}

function* getSection({ payload }) {

  try {
    const { data } = yield call(getSectionApi, payload);
    yield put(getSectionSuccess(data));
  } catch (error) {
    yield put(getSectionFailure(error.response.data || error));
  }
}

function* addSection({ payload }) {

  try {
    const { data } = yield call(addSectionApi, { data: payload.data, page: payload.page });

    yield put(addSectionSuccess(data));

    payload.navigate(`/${payload.page}`);
  } catch (error) {
    yield put(addSectionFailure(error.response.data || error));
  }
}

function* editSection({ payload }) {

  try {
    const { data } = yield call(editSectionApi, { data: payload.data, slug: payload.slug, page: payload.page });
    const sectionAfterEdit = data.sections.find(section => section.slug === payload.slug);

    if (payload?.navigate) {
      payload.navigate(`/${payload.page}`);
    }
    yield put(editSectionSuccess(sectionAfterEdit));
  } catch (error) {
    yield put(editSectionFailure(error.response.data || error));
  }
}

function* deleteSection({ payload }) {

  try {
    yield call(deleteSectionApi, payload);

    yield put(deleteSectionSuccess(payload));
  } catch (error) {
    yield put(deleteSectionFailure(error.response.data || error));
  }
}

export function* watchGetSection() {
  yield takeEvery(GET_SECTION, getSection);
}

export function* watchAddSection() {
  yield takeEvery(ADD_SECTION, addSection);
}

export function* watchEditSection() {
  yield takeEvery(EDIT_SECTION, editSection);
}

export function* watchDeleteSection() {
  yield takeEvery(DELETE_SECTION, deleteSection);
}

export function* watchGetAllPageSection() {
  yield takeEvery(GET_ALL_PAGE_SECTIONS, getAllPageSections);
}

function* sectionSaga() {
  yield all([
    fork(watchGetSection),
    fork(watchEditSection),
    fork(watchGetAllPageSection),
    fork(watchAddSection),
    fork(watchDeleteSection),
  ]);
}

export default sectionSaga;
