import {

  ADD_SECTION,
  ADD_SECTION_FAILURE,
  ADD_SECTION_SUCCESS,
  DELETE_SECTION,
  DELETE_SECTION_FAILURE,
  DELETE_SECTION_SUCCESS,
  EDIT_SECTION,
  EDIT_SECTION_FAILURE,
  EDIT_SECTION_SUCCESS,

  GET_ALL_PAGE_SECTIONS,
  GET_ALL_PAGE_SECTIONS_FAILURE,
  GET_ALL_PAGE_SECTIONS_SUCCESS,

  GET_SECTION,
  GET_SECTION_FAILURE,
  GET_SECTION_SUCCESS
} from "./actionTypes";

export const getAllPageSections = (payload) => {
  return {
    type: GET_ALL_PAGE_SECTIONS,
    payload: payload,
  };
}

export const getAllPageSectionsSuccess = (payload) => {
  return {
    type: GET_ALL_PAGE_SECTIONS_SUCCESS,
    payload: payload,
  };
}

export const getAllPageSectionsFailure = (error) => {
  return {
    type: GET_ALL_PAGE_SECTIONS_FAILURE,
    payload: error,
  };
}

export const getSection = (payload) => {
  return {
    type: GET_SECTION,
    payload: payload,
  };
}

export const getSectionSuccess = (payload) => {
  return {
    type: GET_SECTION_SUCCESS,
    payload: payload,
  };
}

export const getSectionFailure = (error) => {
  return {
    type: GET_SECTION_FAILURE,
    payload: error,
  };
}

export const addSection = (payload) => {
  return {
    type: ADD_SECTION,
    payload: payload,
  };
}
export const addSectionSuccess = (payload) => {
  return {
    type: ADD_SECTION_SUCCESS,
    payload: payload,
  };
}

export const addSectionFailure = (error) => {
  return {
    type: ADD_SECTION_FAILURE,
    payload: error,
  };
}

export const editSection = (payload) => {
  return {
    type: EDIT_SECTION,
    payload: payload,
  };
}
export const editSectionSuccess = (payload) => {
  return {
    type: EDIT_SECTION_SUCCESS,
    payload: payload,
  };
}

export const editSectionFailure = (error) => {
  return {
    type: EDIT_SECTION_FAILURE,
    payload: error,
  };
}

export const deleteSection = (payload) => {
  return {
    type: DELETE_SECTION,
    payload: payload,
  };
}
export const deleteSectionSuccess = (payload) => {
  return {
    type: DELETE_SECTION_SUCCESS,
    payload: payload,
  };
}

export const deleteSectionFailure = (error) => {
  return {
    type: DELETE_SECTION_FAILURE,
    payload: error,
  };
}
