import {
  GET_ALL_SETTINGS,
  GET_ALL_SETTINGS_SUCCESS,
  GET_ALL_SETTINGS_FAILURE,

  EDIT_SETTINGS,
  EDIT_SETTINGS_SUCCESS,
  EDIT_SETTINGS_FAILURE,

} from "./actionTypes";

export const getAllSettings = () => {
  return {
    type: GET_ALL_SETTINGS,
  };
}
export const getAllSettingsSuccess = (payload) => {
  return {
    type: GET_ALL_SETTINGS_SUCCESS,
    payload: payload,
  };
}

export const getAllSettingsFailure = (error) => {
  return {
    type: GET_ALL_SETTINGS_FAILURE,
    payload: error,
  };
}

export const editSettings = (payload) => {
  return {
    type: EDIT_SETTINGS,
    payload: payload
  };
}
export const editSettingsSuccess = (payload) => {
  return {
    type: EDIT_SETTINGS_SUCCESS,
    payload: payload,
  };
}

export const editSettingsFailure = (error) => {
  return {
    type: EDIT_SETTINGS_FAILURE,
    payload: error,
  };
}

