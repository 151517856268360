import ControlArea from 'components/Layout/ControlArea';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPageSections } from 'store/actions';
import Icon from "assets/svgs/socials.svg";
import Table from './Table';
import { toastErrorMessage } from 'helpers/toaster/toastErrorMessage';

const Socials = () => {


  const dispatch = useDispatch();

  const { sections, loading, error } = useSelector((state) => state.section);
  const { formatMessage, locale } = useIntl();

  // error 
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  useEffect(() => {
    dispatch(getAllPageSections("socials"))
  }, [dispatch]);

  return (

    <div className="socials-wrap__">
      <ControlArea
        btnTxt={<FormattedMessage id={"addSocial"} />}
        cardTxt={<FormattedMessage id={"socials"} />}
        icon={Icon}
        url="/socials/addSocial"
      />
      <Table data={sections} loading={loading} />
    </div>

  )
}

export default Socials

