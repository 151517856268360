import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";


import { FormattedMessage, useIntl } from "react-intl";
import { editSection, getSection } from "store/actions";
import Loader from "components/shared/Loader";
import { Input } from "components/shared/FormComponents";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";


const Section = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const { loading, error, section } = useSelector((state) => state.section);
  const { register, reset, handleSubmit, formState: { errors }, } = useForm();


  // get single page by slug 
  useEffect(() => {
    dispatch(getSection({
      page: "home",
      slug: "systems"
    }));
  }, [dispatch]);

  useEffect(() => {

    reset({
      heading: section?.heading,
    });

  }, [reset, section]);

  // error 
  useEffect(() => {

    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }

  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    dispatch(editSection({ slug: "systems", page: "home", data: { section: data } }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4><FormattedMessage id={"editTitleText"} /></h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>

                {/* heading  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="heading.ar" register={register} errors={errors} />

                  </Col>
                  <Col lg={6} xs={12}>
                    <Input langId="title" name="heading.en" register={register} errors={errors} />
                  </Col>
                </Row>

                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;
