import server from "./server";

export const getAllPageSectionsApi = async (page) => {

  const response = await server().get(`/pages/${page}/sections`);

  return response.data;
}
export const getSectionApi = async ({ page, slug }) => {

  const response = await server().get(`/pages/${page}/sections/${slug}`);

  return response.data;
}

export const editSectionApi = async ({ page, slug, data }) => {
  const response = await server().put(`/pages/${page}/sections/${slug}`, data);

  return response.data;
}

export const addSectionApi = async ({ page, data }) => {
  const response = await server().post(`/pages/${page}/sections`, data);

  return response.data;
}

export const deleteSectionApi = async ({ page, slug }) => {
  const response = await server().delete(`/pages/${page}/sections/${slug}`);

  return response.data;
}

export const reorderSectionsApi = async ({ page, data }) => {
  const response = await server().put(`/pages/${page}/sections`, data);
  return response.data;
}

