import { all } from "redux-saga/effects";

//public
import AuthenticationSaga from "./authentication/saga";
import settingsSaga from "./settings/saga";
import sectionSaga from "./section/saga";
import subscribersSaga from "./subscribers/saga";
import socialTypesSaga from "./socialTypes/saga"
import socialsSaga from "./socials/saga"

export default function* rootSaga() {
  yield all(
    [
      AuthenticationSaga(),
      settingsSaga(),
      sectionSaga(),
      subscribersSaga(),
      socialTypesSaga(),
      socialsSaga()
    ]
  );
}