import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getAllPageSections } from "store/actions";

import Table from "./Table";

const AdvirtiseType = () => {
  const dispatch = useDispatch();
  const { sections, loading, error } = useSelector((state) => state.section);
  const { formatMessage, locale } = useIntl();

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  useEffect(() => {
    dispatch(getAllPageSections("contactUs"));
  }, [dispatch]);

  return (
    <div className="property-wrap">
      <Table data={sections} loading={loading} />
    </div>
  );
};

export default AdvirtiseType;
