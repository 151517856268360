import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { FormattedMessage, useIntl } from "react-intl";
import { editSection, getSection } from "store/actions";
import Loader from "components/shared/Loader";
import { useState } from "react";

import ImageUpload from "components/shared/ImageUpload";
import { Input, Textarea } from "components/shared/FormComponents";
import SaveChangesButton from "components/shared/SaveChangesButton";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import SlidersArray from "./SliderArray";
import { handleImage } from "helpers/functions";

const Section = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const { loading, error, section } = useSelector((state) => state.section);
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState({
    preview: null,
    path: "",
  });
  const [sliders, setSliders] = useState([]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // get single page by slug
  useEffect(() => {
    dispatch(
      getSection({
        page: "home",
        slug: "intro",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    reset({
      heading: section?.heading,
      boldHeading: section?.boldHeading,
      description: section?.description,
      imageTitle: section?.imageTitle,
      image: section?.image,
    });

    setSelectedImage({
      preview: null,
      path: section?.image,
    });

    setSelectedBackgroundImage({
      preview: null,
      path: section?.backgroundImage,
    });
    setSliders(section?.sliders?.map((e) => ({ image: handleImage(e) })));
  }, [reset, section]);

  // error
  useEffect(() => {
    if (error) {
      toastErrorMessage(formatMessage({ id: "sendingErrorMessage" }), locale);
    }
  }, [error, formatMessage, locale]);

  const onSubmit = (data) => {
    // if (!selectedImage?.path || !selectedBackgroundImage?.path) {
    //   toastErrorMessage(formatMessage({ id: "imageErrorMessage" }), locale);
    //   return;
    // }
    // // data.image = `/uploads/${selectedImage?.path?.split("/").at(-1)}`;
    data.image = `/uploads/${selectedImage?.path?.split("/").pop()}`;
    data.backgroundImage = `/uploads/${selectedBackgroundImage?.path
      ?.split("/")
      .pop()}`;
    data.sliders = sliders?.map(
      (e) => `/uploads/${e?.image?.split("/").pop()}`
    );
    dispatch(
      editSection({ slug: "intro", page: "home", data: { section: data } })
    );
  };

  if (loading) return <Loader />;
  console.log("sliders", sliders);
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id={"intro"} />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12}>
                    <SlidersArray sliders={sliders} setSliders={setSliders} />
                  </Col>
                </Row>

                {/* backgroundImage  */}
                {/* <Col lg={12} xs={12}>
                  <div className="form-group required">
                    <h5><FormattedMessage id={"backgroundImage"} /></h5>
                    <ImageUpload selectedImage={selectedBackgroundImage} setSelectedImage={setSelectedBackgroundImage} />
                  </div>
                </Col> */}

                {/* heading  */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="heading.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="title"
                      name="heading.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* description */}
                <Row>
                  <Col lg={6} xs={12}>
                    <Textarea
                      langId="description"
                      name="description.ar"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Textarea
                      langId="description"
                      name="description.en"
                      register={register}
                      errors={errors}
                    />
                  </Col>
                </Row>

                {/* image  */}
                {/* <Col lg={12} xs={12}>
                  <div className="form-group required">
                    <h5>
                      <FormattedMessage id={"image"} />
                    </h5>
                    <ImageUpload
                      selectedImage={selectedImage}
                      setSelectedImage={setSelectedImage}
                    />
                  </div>
                </Col> */}

                {/* imageTitle  */}
                {/* <Row>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="imageTitle"
                      name="imageTitle.ar"
                      register={register}
                      errors={errors}
                      rules={{ required: false }}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Input
                      langId="imageTitle"
                      name="imageTitle.en"
                      register={register}
                      errors={errors}
                      rules={{ required: false }}
                    />
                  </Col>
                </Row> */}

                <SaveChangesButton />
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section;
