import React from "react";
import removeImg from "assets/svgs/close.svg";
import { v4 } from "uuid";
import ImageUpdate from "./ImageUpdate";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const SlidersArray = ({ sliders, setSliders }) => {
  const handleInputOnChange = (e, id) => {
    const { name, value } = e.target;

    const newSlidersArray = sliders?.map((slider) => {
      if (slider.id !== id) return slider;

      // make an object with locals inside the big one
      const inputName = name?.split(".")?.[0];
      const local = name?.split(".")?.[1];

      if (local) {
        return {
          ...slider,
          [inputName]: {
            ...slider?.[inputName],
            [local]: value,
          },
        };
      }

      return { ...slider, [name]: value };
    });

    setSliders(newSlidersArray);
  };

  const removeSlider = (sliderId) => {
    const newSliders = sliders?.filter((slider, index) => index !== sliderId);
    setSliders(() => newSliders);
  };

  const addSlider = () => {
    setSliders(() => [...(sliders || []), { id: v4(), image: "" }]);
  };

  return (
    <div className="form-group branches sliders">
      <Row>
        <Col lg={12}>
          <h5>
            <FormattedMessage id={"sliders"} />
          </h5>
          <Row>
            {sliders?.map((slider, index) => {
              return (
                <Col lg="6" xs="12" key={slider.id} className=" p-2 h-100">
                  <div className="add-multi-component">
                    <button
                      type="button"
                      onClick={() => removeSlider(index)}
                      className="removeImg"
                    >
                      <img src={removeImg} alt="" />
                    </button>
                    <Row>
                      <Col lg={12} className="text-center">
                        <ImageUpdate
                          index={index}
                          item={slider}
                          sliders={sliders}
                          setSliders={setSliders}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>

        <Col lg={4} xs={12} className="text-left">
          <button
            type="button"
            className="btn btn-green add_new_section__"
            onClick={() => {
              addSlider();
            }}
          >
            <FormattedMessage id={"addSlider"} />
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default SlidersArray;
