import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_ALL_SETTINGS,
  EDIT_SETTINGS,

} from "./actionTypes";

import {
  getAllSettingsApi,
  editSettingsApi,

} from "api/settings";

import {
  getAllSettingsSuccess,
  getAllSettingsFailure,
  editSettingsSuccess,
  editSettingsFailure,

} from "./actions"


// api calls 
function* getAllSettingss() {
  try {
    const { data } = yield call(getAllSettingsApi);

    yield put(getAllSettingsSuccess(data));
  } catch (error) {
    yield put(getAllSettingsFailure(error.response?.data?.message || error));
  }
}

function* editSettings({ payload }) {

  try {
    const { data } = yield call(editSettingsApi, payload);

    yield put(editSettingsSuccess(data));
  } catch (error) {
    yield put(editSettingsFailure(error.response?.data?.message || error));
  }
}

// watchers
export function* watchgetAllSettings() {
  yield takeEvery(GET_ALL_SETTINGS, getAllSettingss);
}

export function* watcheditSettings() {
  yield takeEvery(EDIT_SETTINGS, editSettings);
}



function* settingsSaga() {
  yield all([fork(watchgetAllSettings)]);
  yield all([fork(watcheditSettings)]);
}

export default settingsSaga;
