import {
  GET_ALL_SOCIALTYPES,
  GET_ALL_SOCIALTYPES_SUCCESS,
  GET_ALL_SOCIALTYPES_FAILURE,

  EDIT_SOCIALTYPE,
  EDIT_SOCIALTYPE_SUCCESS,
  EDIT_SOCIALTYPE_FAILURE,

  DELETE_SOCIALTYPE,
  DELETE_SOCIALTYPE_SUCCESS,
  DELETE_SOCIALTYPE_FAILURE,
  GET_SOCIALTYPE,
  GET_SOCIALTYPE_SUCCESS,
  GET_SOCIALTYPE_FAILURE,
  ADD_SOCIALTYPE,
  ADD_SOCIALTYPE_SUCCESS,
  ADD_SOCIALTYPE_FAILURE,

} from "./actionTypes";

export const getAllSocialTypes = () => {
  return {
    type: GET_ALL_SOCIALTYPES,
  };
}
export const getAllSocialTypesSuccess = (payload) => {
  return {
    type: GET_ALL_SOCIALTYPES_SUCCESS,
    payload: payload,
  };
}

export const getAllSocialTypesFailure = (error) => {
  return {
    type: GET_ALL_SOCIALTYPES_FAILURE,
    payload: error,
  };
}

export const editSocialType = (payload) => {
  return {
    type: EDIT_SOCIALTYPE,
    payload: payload
  };
}
export const editSocialTypeSuccess = (payload) => {
  return {
    type: EDIT_SOCIALTYPE_SUCCESS,
    payload: payload,
  };
}

export const editSocialTypeFailure = (error) => {
  return {
    type: EDIT_SOCIALTYPE_FAILURE,
    payload: error,
  };
}



export const getSocialType = (payload) => {

  return {
    type: GET_SOCIALTYPE,
    payload: payload
  };
}
export const getSocialTypeSuccess = (payload) => {
  return {
    type: GET_SOCIALTYPE_SUCCESS,
    payload: payload,
  };
}

export const getSocialTypeFailure = (error) => {
  return {
    type: GET_SOCIALTYPE_FAILURE,
    payload: error,
  };
}


export const deleteSocialType = (id) => {

  return {
    type: DELETE_SOCIALTYPE,
    payload: id
  }
}

export const deleteSocialTypeSuccess = (id) => {
  return {
    type: DELETE_SOCIALTYPE_SUCCESS,
    payload: id
  }
}

export const deleteSocialTypeFailure = (error) => {
  return {
    type: DELETE_SOCIALTYPE_FAILURE,
    payload: error
  }
}



export const addSocialType = (payload) => {
  return {
    type: ADD_SOCIALTYPE,
    payload: payload
  };
}
export const addSocialTypeSuccess = (payload) => {
  return {
    type: ADD_SOCIALTYPE_SUCCESS,
    payload: payload,
  };
}

export const addSocialTypeFailure = (error) => {
  return {
    type: ADD_SOCIALTYPE_FAILURE,
    payload: error,
  };
}
