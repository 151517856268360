export const handleImage = (image) => {
  if (!image?.includes("http")) {
    if (!image?.startsWith("/")) image = `/${image}`;
    return `${process.env.REACT_APP_URL}${image}`;
  }
  return image || "";
  // return "https://picsum.photos/seed/picsum/200/300";
};

export const handleSearchParamsChange = (search, params) => {
  const url = new URLSearchParams("");
  search = { ...search, ...params };
  Object.entries(search).map(([key, value]) => {
    return url.set(key, value);
  });
  return "?" + url;
};

export const themeSwitcherFunction = () => {
  document.querySelector(".themetoggle").classList.toggle("fire");
  document.querySelector("body").classList.toggle("dark");
};

export const sidebarToggleFunction = () => {
  document.querySelector("body").classList.remove("sidebarToggled");
  document.querySelector(".overlay-s").classList.remove("fire");
  document.querySelector("html").classList.remove("offScroll");
};

export const getSettingsBySlug = (settings, slug) => {
  return settings.find((setting) => {
    return setting._id === slug;
  });
};

export const getToday = () => {
  let today = new Date();
  let dd = String(today.getDate());
  let mm = String(today.getMonth() + 1); //January is 0!
  let yyyy = today.getFullYear();

  today = `${yyyy}-${mm}-${dd}`;
  return today;
};

export const getDateOfBirthWithFormat = (date) => {
  let newDateWithFormate = new Date(date);

  let dd = String(newDateWithFormate.getDate()).padStart(2, "0");
  let mm = String(newDateWithFormate.getMonth() + 1).padStart(2, "0");
  let yyyy = newDateWithFormate.getFullYear();

  newDateWithFormate = `${yyyy}-${mm}-${dd}`;
  return newDateWithFormate;
};

export const selctOptionValues = (options, selectedOption) => {
  const option = options?.find((option) => option?.value === selectedOption);

  return option;
};

export const handleSkillsDefaultValues = (options) => {
  return options?.map((option) => {
    return {
      value: option?.id,
      label: option?.name,
    };
  });
};
