import {
  GET_ALL_SETTINGS,
  GET_ALL_SETTINGS_SUCCESS,
  GET_ALL_SETTINGS_FAILURE,

  EDIT_SETTINGS,
  EDIT_SETTINGS_SUCCESS,
  EDIT_SETTINGS_FAILURE,

} from "./actionTypes";


const initialState = {
  settings: [],
  loading: false,
  error: ""
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all settings  
    case GET_ALL_SETTINGS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_ALL_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        settings: action.payload.siteInfo
      };
      break;

    case GET_ALL_SETTINGS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false
      };
      break;

    // edit MAIN Settings 
    case EDIT_SETTINGS:
      state = { ...state, loading: true }

      break;

    case EDIT_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        settings: action.payload.siteInfo
      }
      break;

    case EDIT_SETTINGS_FAILURE:
      state = {
        ...state, loading: false, error: action.payload
      }
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
