import React, { useEffect, useState } from "react";
import removeImg from "assets/svgs/close.svg";
import { v4 } from "uuid";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useFieldArray } from "react-hook-form";
import ImageUpload from "components/shared/ImageUpload";
import { handleImage } from "helpers/functions";

const FeaturesArray = ({ control, register, errors }) => {
  const { fields, remove, append, update } = useFieldArray({
    control,
    name: "instaImages",
  });

  return (
    <div className="form-group branches features">
      <h5>
        <FormattedMessage id={"instaImages"} />
        <button
          type="button"
          className="btn btn-green"
          onClick={() => {
            append({});
          }}
        >
          <FormattedMessage id={"addInstaImage"} />
        </button>
      </h5>
      <Row>
        {fields.map((item, index) => (
          <Item
            item={item}
            remove={() => remove(index)}
            register={register}
            errors={errors}
            key={index}
            index={index}
            update={update}
          />
        ))}
      </Row>
    </div>
  );
};

const Item = ({ item, remove, register, errors, index, update }) => {
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: "",
  });

  useEffect(() => {
    setSelectedImage({
      preview: null,
      path: handleImage(item?.image),
    });
  }, []);
  useEffect(() => {
    update(index, {
      ...item,
      image: `/uploads/${selectedImage?.path?.split("/").at(-1)}`,
    });
  }, [selectedImage]);

  return (
    <Col xl={6} lg={6} md={6} xs={12}>
      <div key={item?.id} className="add-multi-component">
        <button type="button" onClick={remove} className="removeImg">
          <img src={removeImg} alt="" />
        </button>
        <Row>
          <Col lg={12} xs={12}>
            <div className="form-group required">
              <h5>
                <FormattedMessage id={"image"} />
              </h5>
              <ImageUpload
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
            </div>
          </Col>

          <Col lg={12} xs={12}>
            <div className="form-group">
              <h5>
                <FormattedMessage id={"link"} />
              </h5>
              <input
                type="url"
                className="form-control form-outline"
                {...register(`instaImages.${index}.url`, {
                  required: true,
                })}
                dir="ltr"
              />
              <p className="error-hint">
                {errors?.instaImages?.[index]?.["url"]?.type === "required" && (
                  <FormattedMessage id={"requiredField"} />
                )}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default FeaturesArray;
