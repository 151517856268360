import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  GET_ALL_SOCIALS,
  EDIT_SOCIAL,
  DELETE_SOCIAL,
  GET_SOCIAL,
  ADD_SOCIAL,
} from "./actionTypes";

import {
  getAllSocialsApi,
  editSocialApi,
  deleteSocialApi,
  getSocialApi,
  addSocialApi
} from "api/socials";

import {
  getAllSocialsSuccess,
  getAllSocialsFailure,
  editSocialSuccess,
  editSocialFailure,
  deleteSocialSuccess,
  deleteSocialFailure,
  getSocialSuccess,
  getSocialFailure,
  addSocialSuccess,
  addSocialFailure,

} from "./actions"


// api calls 
function* getAllSocials() {
  try {
    const { data } = yield call(getAllSocialsApi);

    yield put(getAllSocialsSuccess(data));
  } catch (error) {
    yield put(getAllSocialsFailure(error.response?.data?.message || error));
  }
}

function* editSocial({ payload }) {

  try {
    const { data } = yield call(editSocialApi, payload);

    yield put(editSocialSuccess(data));

    payload.navigate("/socials")
  } catch (error) {
    yield put(editSocialFailure(error.response?.data?.message || error));
  }
}

function* getSocial({ payload }) {

  try {
    const { data } = yield call(getSocialApi, payload);


    yield put(getSocialSuccess(data));
  } catch (error) {
    yield put(getSocialFailure(error.response?.data?.message || error));
  }
}

function* deleteSocial({ payload }) {
  try {
    yield call(deleteSocialApi, payload);
    yield put(deleteSocialSuccess(payload));


  } catch (error) {
    yield put(deleteSocialFailure(error));
  }
}

function* addSocial({ payload }) {

  try {
    const { data } = yield call(addSocialApi, payload.data);

    yield put(addSocialSuccess(data));

    payload.navigate("/socials");
  } catch (error) {
    yield put(addSocialFailure(error.response?.data?.message || error));
  }
}

// watchers
export function* watchgetAllSocials() {
  yield takeEvery(GET_ALL_SOCIALS, getAllSocials);
}

export function* watcheditSocial() {
  yield takeEvery(EDIT_SOCIAL, editSocial);
}
export function* watchGetSocial() {
  yield takeEvery(GET_SOCIAL, getSocial);
}

export function* watchDeleteSocial() {
  yield takeEvery(DELETE_SOCIAL, deleteSocial);
}

export function* watchAddSocial() {
  yield takeEvery(ADD_SOCIAL, addSocial);
}



function* socialsSaga() {
  yield all([
    fork(watchgetAllSocials),
    fork(watcheditSocial),
    fork(watchDeleteSocial),
    fork(watchGetSocial),
    fork(watchAddSocial),
  ]);
}

export default socialsSaga;
